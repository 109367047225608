import api from "../api"

const mutationFn = async (args) => {
  const { url, method = "post", variables, responseType } = args
  const reqUrl = typeof url === "function" ? url(variables) : url

  const { data = {}, route = "v1/logistics", extraUrl = "", urlSearchParams = {} } = variables
  const baseURL = `${process.env.REACT_APP_API_BASE_URL}${route}`

  const options = {
    baseURL,
    url: reqUrl + extraUrl,
    method,
    params: urlSearchParams,
    data,
  }

  if (responseType) {
    options.responseType = responseType
  }

  return api(options).catch((err) => err)
}

export default mutationFn
